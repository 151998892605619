import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import styles from "./Button.module.css"

const Button = ({ children, className, theme, ...props }) => {
  const themeClass = {
    [styles.dark]: theme === "dark",
    [styles.light]: theme === "light",
    [styles.clear]: theme === "clear",
    [styles.green]: theme === "green",
  }

  return (
    <button
      {...props}
      className={classNames(styles.button, themeClass, className)}
    >
      {children}
    </button>
  )
}

Button.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  theme: PropTypes.oneOf(["dark", "light", "clear"]),
}

export default Button
