import React from "react"
import classNames from "classnames"

import SampleReportModal from "../SampleReportModal/SampleReportModal"
import Button from "../Button/Button"
import footerEbook from "../../images/property-prediction-report.png"
import styles from "./FooterBannerSection.module.css"

const FooterBannerSection = () => (
  <section className={styles.background}>
    <div className="container">
      <div className={styles.container}>
        <img
          className={classNames("img-fluid", styles.image)}
          src={footerEbook}
          alt="eBook"
        />
        <span className={styles.text}>Check Out A Sample Report</span>
        <div className="d-flex justify-content-center">
          <SampleReportModal
            opener={<Button theme="clear">Sample report</Button>}
          />
        </div>
      </div>
    </div>
  </section>
)

export default FooterBannerSection
