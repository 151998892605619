import React from "react"
import { Link } from "gatsby"
import classNames from "classnames"

import logoHeader from "../../images/suburb-growth-logo-04.svg"
import styles from "./Header.module.css"

const navItems = [
  {
    label: "How It Works",
    href: "/how-it-works",
  },
  {
    label: "Who's It For",
    href: "/who-is-it-for",
  },
  {
    label: "Why Use It",
    href: "/why-use-it",
  },
  {
    label: "Pricing",
    href: "/pricing",
  },
  {
    label: "Contact Us",
    href: "/contact-us",
  },
]

const Header = () => (
  <header className={styles.container}>
    {typeof process.env.INFO_BANNER_MESSAGE === "string" ? (
      <div className={classNames("info-banner", styles.infoBanner)}>
        {process.env.INFO_BANNER_MESSAGE}
      </div>
    ) : (
      ""
    )}
    <nav
      className={classNames(
        "navbar navbar-expand-lg navbar-dark",
        styles.navbar
      )}
    >
      <div className="container">
        <Link to="/">
          <img
            className={styles.logo}
            src={logoHeader}
            alt="Suburb Growth logo"
          />
        </Link>
        <button
          className={classNames("navbar-toggler", styles.hamburgerButton)}
          type="button"
          data-toggle="collapse"
          data-target="#primaryNavbarCollapse"
          aria-controls="primaryNavbarCollapse"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>

        <div className="collapse navbar-collapse" id="primaryNavbarCollapse">
          <ul className="navbar-nav ml-auto">
            {navItems.map((item, i) => (
              <li key={i}>
                <Link
                  className={classNames("nav-link", styles.navItem)}
                  activeClassName={styles.navItemActive}
                  to={item.href}
                >
                  {item.label}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </nav>
  </header>
)

export default Header
