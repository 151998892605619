import React, { Fragment } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import classNames from "classnames"

import FooterBannerSection from "./FooterBannerSection"
import footerLogo from "../../images/suburb-logo.jpg"
import styles from "./Footer.module.css"

import srpLogo from "../../images/srp-logo.svg"
import locationScoreLogo from "../../images/location-score-logo-small.jpg"
import dsrLogo from "../../images/dsr-logo-small.jpg"
import sellHoldLogo from "../../images/sell-hold-logo-small.jpg"

const Footer = () => (
  <Fragment>
    <FooterBannerSection />

    <footer className={styles.container}>
      <img
        className={styles.footerLogo}
        src={footerLogo}
        alt="Suburb Growth logo"
      />

      <div className={styles.promoLinkMain}>
        <div className={styles.selectLogo}>
          <img src={srpLogo} />
          <br />
          <a
            className={styles.selectBtn}
            href="https://selectresidentialproperty.com.au/"
            target="_blank"
          >
            The home of
          </a>
        </div>
        <div className={styles.promoA}>
          <img src={locationScoreLogo} />
          <br />
          <a
            className={styles.promoBtn}
            href="https://www.locationscore.com.au/"
            target="_blank"
          >
            Learn More
          </a>
        </div>
        <div className={styles.promoB}>
          <img src={dsrLogo} />
          <br />
          <a
            className={styles.promoBtn}
            href="https://dsrdata.com.au/"
            target="_blank"
          >
            Learn More
          </a>
        </div>
        <div className={styles.promoC}>
          <img src={sellHoldLogo} />
          <br />
          <a
            className={styles.promoBtn}
            href="https://sellorhold.com.au/"
            target="_blank"
          >
            Learn More
          </a>
        </div>
      </div>

      <div className="container">
        <ul className={classNames("list-inline", styles.list)}>
          <ListItem>&copy; Suburb Growth</ListItem>
          <ListItem>|</ListItem>
          <ListItem>All Rights Reserved</ListItem>
          <ListItem>|</ListItem>
          <ListItem shouldPrint={false}>
            <Link to="/privacy-policy">Privacy Policy</Link>
          </ListItem>
          <ListItem shouldPrint={false}>|</ListItem>
          <ListItem shouldPrint={false}>
            <Link to="/terms-and-conditions">Terms and Conditions</Link>
          </ListItem>
        </ul>
      </div>
    </footer>
  </Fragment>
)

const ListItem = ({ children, shouldPrint = true }) => (
  <li
    className={classNames("list-inline-item", {
      "d-print-none": !shouldPrint,
    })}
  >
    {children}
  </li>
)

ListItem.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Footer
