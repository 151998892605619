import React, { Fragment } from "react"
import PropTypes from "prop-types"
import Modal from "react-modal"
import "bootstrap/dist/css/bootstrap.min.css"

import Header from "../Header/Header"
import Footer from "../Footer/Footer"
import "./SiteLayout.module.css"

// SSR rendering: https://www.gatsbyjs.org/docs/debugging-html-builds/#how-to-check-if-window-is-defined
if (typeof window !== "undefined") {
  // eslint-disable-next-line no-unused-expressions
  import("bootstrap")
}

Modal.setAppElement("#___gatsby")

const Layout = ({ children }) => (
  <Fragment>
    <Header />
    <main>{children}</main>
    <Footer />
  </Fragment>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
