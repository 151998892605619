import React, { useState, Fragment } from "react"
import { navigate } from "gatsby"
import Modal from "react-modal"
import { Formik, Form, Field, ErrorMessage } from "formik"
import * as Yup from "yup"
import LoadingOverlay from "react-loading-overlay"

import Button from "../Button/Button"
import styles from "./SampleReportModal.module.css"
import ReCAPTCHA from "react-google-recaptcha"

const SampleReportModal = ({ opener }) => {
  const [isOpen, setOpen] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [isValidToSubmit, setValidToSubmit] = useState(false)
  return (
    <Fragment>
      <span onClick={() => setOpen(true)}>{opener}</span>

      <Modal
        isOpen={isOpen}
        onRequestClose={() => setOpen(false)}
        contentLabel="Sample Report Dialog"
        className={styles.modal}
      >
        <LoadingOverlay active={isLoading} spinner text="Processing...">
          <button
            type="button"
            className={`close ${styles.xButton}`}
            aria-label="Close"
            onClick={() => setOpen(false)}
          >
            <span aria-hidden="true">&times;</span>
          </button>

          <Formik
            initialValues={{
              firstName: "",
              lastName: "",
              email: "",
            }}
            validationSchema={SampleReportFormSchema}
            onSubmit={async values => {
              setLoading(true)
              const res = await fetch(
                `${process.env.GATSBY_API_URL}prospects`,
                {
                  method: "POST",
                  headers: { "Content-Type": "application/json" },
                  body: JSON.stringify(values),
                }
              )
              const json = await res.json()
              setLoading(false)

              if (json.success) navigate("/report/growth-prediction/?id=free")
              // else there was an error
            }}
          >
            {({ isSubmitting }) => (
              <Form noValidate>
                <h3 className={`text-center ${styles.viewReport}`}>
                  View Report
                </h3>

                <div>
                  <Field
                    name="firstName"
                    className={styles.input}
                    placeholder="First name"
                    autoFocus
                  />
                  <ErrorMessage
                    name="firstName"
                    component="div"
                    className={styles.error}
                  />
                </div>

                <div>
                  <Field
                    name="lastName"
                    className={styles.input}
                    placeholder="Last name"
                  />
                  <ErrorMessage
                    name="lastName"
                    component="div"
                    className={styles.error}
                  />
                </div>

                <div>
                  <Field
                    name="email"
                    type="email"
                    className={styles.input}
                    placeholder="Email"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className={styles.error}
                  />
                </div>

                <div>
                  <ReCAPTCHA
                    sitekey={process.env.RECAPTCHA_SITE_KEY}
                    onChange={() => {
                      setValidToSubmit(true)
                    }}
                  />
                </div>
                {isValidToSubmit && (
                  <Button
                    type="submit"
                    theme="dark"
                    className={styles.button}
                    disabled={isSubmitting}
                  >
                    Submit
                  </Button>
                )}
              </Form>
            )}
          </Formik>
        </LoadingOverlay>
      </Modal>
    </Fragment>
  )
}

// ============================================================================
// HELPERS
// ============================================================================

const SampleReportFormSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, "First name must be at least 2 characters")
    .max(50, "First name must be at most 50 characters")
    .required("First name is required"),
  lastName: Yup.string()
    .min(2, "Last name must be at least 2 characters")
    .max(50, "Last name must be at most 50 characters")
    .required("Last name is required"),
  email: Yup.string()
    .email("Email must be a valid email")
    .required("Email is required"),
})

export default SampleReportModal
